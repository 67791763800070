import { findWhere, isEqual, keys, where } from 'underscore';
import { codeString, stringToDate } from '../../modules/string_module';
import { meanValue } from '../../modules/math_module';
import { FileModule } from '../../modules/file_module';
import { arraysAreEqual } from './../../modules/array_module';
import { HelperUser } from '../helper_user/helper_user';
import { HelperSegmentationBMode } from '../helper_segmentation/helper_segmentation_bmode';
import { HelperConfig } from '../helper_config/helper_config';
import { HelperStudiesStore } from './helper_studies_store';
import { HelperStudiesBackend } from './helper_studies_backend';
import { HelperStudiesLocalStorage } from './helper_studies_localstorage';
import { HelperStudiesState } from './helper_studies_state';

let saving = false;
let saved_images = [];
let saved_missing_images = [];
export class HelperStudies {
    static get(currentproject) {
        // let user_type = HelperUser.getUserType();
        let user = HelperUser.getUser();
        // if (user_type === "Ground-truth generator") {
        let currentprojectObj = HelperStudiesStore.getCurrentProjectObj();
       
        return HelperStudiesBackend.getStudies_GroundTruthUser(currentprojectObj, user.user_token).then((result) => {
            if ("error" in result) {
                return result
            } else {
                return HelperStudiesBackend.getPatientIDs(result.studies, user.user_token).then(() => {
                    HelperStudiesStore.setCurrentProjectStudies(result.studies);
                    return HelperStudiesBackend.getAllScores(user.user_token).then((scores) => {
                        let scores_list = classifyScores(scores);
                        return HelperUser.getUserRolesFromDB().then(user_roles => {
                            return parseAnalysisList(result.studies, result.analysis_list, scores_list, user.user_email, user.user_type, user_roles);
                        });
                    });    
                });
            }
        });
        // }
    }

    static setInitialCurrentProject(projects) {
        let currentproject = projects[0];
        const urlParams = new URLSearchParams(window.location.search);
        const project = urlParams.get('project');
        if (project !== undefined && project !== null && project !== "undefined") {
            currentproject = project;
        }
        return currentproject
    }

    static isCurrentProjectNewIDs() {
        let project = HelperStudies.getCurrentProject();
        if (["chd-bcnatal","impact-5y","media","omega","p4copd","pe360","propionic-acidemia","rpm-preterm","tofsickkids","twins"].includes(project) || project.includes("aortic-arch")) {
            return true;
        } else {
            return false;
        }
    }

    static getMinioBucket(patient_id) {
        if (this.isCurrentProjectNewIDs()) {
            return "transcor-patients-" + Math.floor(parseInt(patient_id)/1000);
        } else {
            return HelperStudies.getCurrentProjectObj().bucket;
        }
    }

    static loadPrismaReviewData(user_token) {
        let params = { bucket: "iap-fedoc-arc", object: "consensus_pending" }
        return HelperStudiesBackend.getPresignedUrlsMinio(params, user_token).then((all_urls) => {
            let json_urls = all_urls.filter(url => url.includes("study_images.json"));
            let json_promises = [];
            json_urls.map((url) => {
                json_promises.push(fetch(url).then(res => res.json()).then(json => {
                    return json;
                }).catch( (response) =>{
                    console.log(response.status, response.statusText);
                }));
            });
            return Promise.all(json_promises).then((studies) => {
                studies.map(study => {
                    let split = study.study_id.lastIndexOf("_");
                    let study_path = study.study_id.substring(0, split) + "/" + study.study_id.substring(split+1) + "/";
                    study.images.map(image => {
                        let idx_url = all_urls.findIndex(u => u.includes(study_path+image.image_filename));
                        image.url = all_urls[idx_url];
                    });
                });
                return studies;
            });
        });
    }

    static loadPrismaDataManagement(user_token) {
        let params = { bucket: "iap-platform-bucket", object: "project_statistics/fedoc-arc/data_anomalies.json" }
        return HelperStudiesBackend.getPresignedUrlsMinio(params, user_token).then(url => {
            if (url[0]?.includes("data_anomalies.json")) {
                return FileModule.readJson(url[0]).then((stats) => {
                    return [stats, url[0]];
                });
            }
        });
    }

    static getProjectStatistics(items) {
        let statistics = {
            total: items.length,
            selected: 0,
            annotated: 0,
            segmented_dop: 0,
            segmented_2d: 0
        };
        if (items.some(item => item.type === "fetal")) {
            statistics.segmented_bio = 0;
        }
        items.map((item) => {
            let status = item.status;
            for (const [key, value] of Object.entries(status)) {
                if (key !== "validated") {
                    if (statistics[key] && value) {
                        statistics[key] = statistics[key] + 1;

                    } else if (value) {
                        statistics[key] = 1;
                    }
                }
            }
            return true;
        });
        return statistics;
    }

    static getProjectDataStats() {
        let config = HelperConfig.getAPIConfig();
        if (config.iap_bucket) {
            let project = this.getCurrentProject();
            let params = { bucket: config.iap_bucket, object: "project_statistics/"+project+"/data_statistics.json" }
            let user = HelperUser.getUser();
            return HelperStudiesBackend.getPresignedUrlsMinio(params, user.user_token).then(url => {
                if (url[0]?.includes("data_statistics.json")) {
                    return FileModule.readJson(url[0]).then((stats) => {
                        params.object = "project_statistics/"+project+"/plot";
                        return HelperStudiesBackend.getPresignedUrlsMinio(params, user.user_token).then(urls => {
                            stats.plots = urls;
                            return stats;
                        });
                    });
                }
            });    
        }
        return new Promise((resolve, reject) => {
            resolve(false)
        });
    }

    static getProjectIQScores() {
        return HelperStudiesBackend.getAllScores(HelperUser.getUser().user_token).then((result) => {
            if (result && result.length > 0) {
                const test_users = HelperConfig.getTestUsers();
                result = result.filter(sc => !test_users.includes(sc.user));
                let unique_scores = [];
                let scores = [];
                for (const score of result) {
                    if (!unique_scores.includes(score.study_id+"_"+score.image_filename)) {
                        let possible_scores = where(result, { study_id: score.study_id, image_filename: score.image_filename });
                        let dates = possible_scores.map(s => Date.parse(s.date));
                        scores.push(possible_scores[dates.indexOf(Math.max(...dates))]);
                        unique_scores.push(score.study_id+"_"+score.image_filename);
                    }
                }
                return scores;
            } else {
                return false;
            }
        });
    }

    static getProjectIQScoresByDate(scores, dates) {
        const getStudyDate = (study_id) => study_id.split("_").pop();
        let new_scores = scores.filter(sc => sc.study_id);
        if (dates.from) {
            new_scores = new_scores.filter(sc => Date.parse(stringToDate(getStudyDate(sc.study_id))) >= Date.parse(dates.from));
        }
        if (dates.to) {
            let full_day = new Date(dates.to);
            full_day.setDate(full_day.getDate() + 1);
            new_scores = new_scores.filter(sc => Date.parse(stringToDate(getStudyDate(sc.study_id))) <= Date.parse(full_day));
        }
        // Average number of days between study acquisition and QC
        // const dist = new_scores.map(sc => Date.parse(sc.date) - Date.parse(stringToDate(getStudyDate(sc.study_id))));
        // console.log(meanValue(dist)/1000/60/60/24)
        // -----
        return new_scores;
    }

    static getProjectIQScoresByGA(scores, weeks) {
        const studies = HelperStudiesStore.getCurrentProjectStudies();
        const ga_obj = {};
        studies.map(study => {
            ga_obj[study.id] = study.fetal_parameters;
        });
        const ga = {
            from: weeks.from === ""? 0 : parseInt(weeks.from),
            to: weeks.to === ""? 0 : parseInt(weeks.to)
        }
        let new_scores = scores.map(sc => sc);
        if (ga.from > 0) {
            new_scores = new_scores.filter(sc => ga_obj[sc.study_id] && ga_obj[sc.study_id].gestational_age >= ga.from);
        }
        if (ga.to > 0) {
            new_scores = new_scores.filter(sc => ga_obj[sc.study_id] && ga_obj[sc.study_id].gestational_age <= ga.to);
        }
        return new_scores;
    }

    static getProjectIQStats(scores) {
        if (scores) {
            const stats = { general: {}, image_types: {} };
            const stats_by_study = {};
            for (const score of scores) {
                if (!stats_by_study[score.study_id]) {
                    stats_by_study[score.study_id] = { total: 0, accepted: 0, quick_total: 0, quick_accepted: 0 };
                }
                if (!stats.image_types[score.image_label]) {
                    stats.image_types[score.image_label] = { total: 0, sections: {}, quick_total: 0, quick_accepted: 0 };
                    let template = HelperConfig.getImageScoreTemplate(score.image_label);
                    for (const section of Object.keys(template)) {
                        stats.image_types[score.image_label].sections[section] = {};
                        for (const point of template[section]) {
                            stats.image_types[score.image_label].sections[section][point.name] = {};
                            for (const option of point.options) {
                                stats.image_types[score.image_label].sections[section][point.name][option] = 0;
                            }
                            stats.image_types[score.image_label].sections[section][point.name]["N/A"] = 0;
                        }
                    }
                }
                if (score.type === "score-accept") {
                    stats_by_study[score.study_id].quick_total += 1;
                    stats.image_types[score.image_label].quick_total += 1;
                    if (score.content === "yes") {
                        stats_by_study[score.study_id].quick_accepted += 1;
                        stats.image_types[score.image_label].quick_accepted += 1;
                    }
                } else {
                    stats_by_study[score.study_id].total += 1;
                    stats.image_types[score.image_label].total += 1;
                    for (const section of Object.keys(stats.image_types[score.image_label].sections)) {
                        for (const point of Object.keys(stats.image_types[score.image_label].sections[section])) {
                            let point_obj = findWhere(score.content[section], { name: point });
                            if (point_obj) {
                                stats.image_types[score.image_label].sections[section][point][point_obj.value] += 1;
                                if (point === "Suboptimal image" && point_obj.value === "No") {
                                    stats_by_study[score.study_id].accepted += 1;
                                }
                            } else {
                                stats.image_types[score.image_label].sections[section][point]["N/A"] += 1;
                            }
                        }
                    }    
                }
            }
            let normal = Object.keys(stats_by_study).filter(s => stats_by_study[s].total > 0);
            stats.general.total_studies = normal.length;
            stats.general.average_acceptance = 100*meanValue(normal.map(s => stats_by_study[s].accepted/stats_by_study[s].total));
            let quick = Object.keys(stats_by_study).filter(s => stats_by_study[s].quick_total > 0);
            stats.general.quick_total = quick.length;
            stats.general.quick_acceptance = 100*meanValue(quick.map(s => stats_by_study[s].quick_accepted/stats_by_study[s].quick_total));
            return stats;
        } else {
            return false;
        }
    }

    static createStudiesTableData(from, data) {
        let rows = [];
        data.map((study) => {
            let row = {
                "patient": study.patient,
                "patient_show": study.patient_show,
                "project": study.project,
                "data_type": study.data_type,
                "owner": study.editor,
                "status": study.status,
                "date": study.date,
                // "is_open": study.is_open,
                "is_open": this.checkStudyOpen(study.is_open, study.updated_at),
                "editor": study.editor,
                "updated_at": study.updated_at,
                "validated_by": study.validated_by,
                "study_id": study.study_id,
                "study_type": study.study_type
            };
            rows.push(row);
            return true;
        });

        const columns = HelperConfig.getTabColumns(from);
        return { rows: rows, columns: columns };
    }

    static setCurrentProject(currentproject) {
        HelperStudiesStore.setCurrentProject(currentproject);
        return this.getProject(currentproject).then(object => {
            HelperStudiesStore.setCurrentProjectObj(object);
            return true
        });
    }

    static setStudyId(study_id) {
        HelperStudiesLocalStorage.setStudyId(study_id);
        HelperStudiesStore.setStudyId(study_id);
    }

    static setStudyDate(study_date) {
        HelperStudiesLocalStorage.setStudyDate(study_date);
        HelperStudiesStore.setStudyDate(study_date);
    }

    static setPatientId(patient_id) {
        HelperStudiesLocalStorage.setPatientId(patient_id);
        HelperStudiesStore.setPatientId(patient_id);
    }

    static setPatientIdShow(patient_id_show) {
        HelperStudiesLocalStorage.setPatientIdShow(patient_id_show);
        HelperStudiesStore.setPatientIdShow(patient_id_show);
    }

    static setDataType(data_type) {
        HelperStudiesLocalStorage.setDataType(data_type);
        HelperStudiesStore.setDataType(data_type);
    }

    static setStudyState(study_state){
        HelperStudiesStore.setStudyState(study_state);
    }

    static setEditor(editor) {
        HelperStudiesStore.setEditor(editor);
    }

    static setValidated(validated) {
        HelperStudiesStore.setValidated(validated);
    }

    static setHideComplete(hide) {
        HelperStudiesStore.setHideComplete(hide);
    }

    static setStudyImages(images) {
        HelperStudiesStore.setStudyImages(images);
    }

    static setImagesToLoad(images) {
        HelperStudiesStore.setImagesToLoad(images);
    }

    static setCurrentPage(page) {
        HelperStudiesStore.setCurrentPage(page);
    }

    static setCurrentSorting(sort) {
        HelperStudiesStore.setCurrentSorting(sort);
    }

    static setCurrentSearch(search) {
        HelperStudiesStore.setCurrentSearch(search);
    }

    static getProject(project) {
        return HelperStudiesBackend.getProject(project, HelperUser.getUser().user_token).then((project_obj) => {
            return project_obj[0];
        });
    }

    static getCurrentProject() {
        return HelperStudiesStore.getCurrentProject();
    }

    static getCurrentProjectObj() {
        return HelperStudiesStore.getCurrentProjectObj();
    }

    static getCurrentProjectStudies() {
        return HelperStudiesStore.getCurrentProjectStudies();
    }

    static getCurrentProjectRequiredCycles() {
        const events = this.getCurrentProjectObj().cardiac_cycles;
        return events === "user defined"? false : events;
    }

    static getCurrentProjectMaxCycles() {
        const max_events = this.getCurrentProjectObj().cardiac_cycles;
        return max_events === "user defined"? 1000 : max_events;
    }

    static getStudyId() {
        return HelperStudiesStore.getStudyId();
    }

    static getStudyDate() {
        return HelperStudiesStore.getStudyDate();
    }

    static getPatientId() {
        return HelperStudiesStore.getPatientId();
    }

    static getPatientIdShow() {
        return HelperStudiesStore.getPatientIdShow();
    }

    static getDataType() {
        return HelperStudiesStore.getDataType();
    }

    static getStudyState(){
        return HelperStudiesStore.getStudyState();
    }

    static getEditor() {
        return HelperStudiesStore.getEditor();
    }

    static getValidated() {
        return HelperStudiesStore.getValidated();
    }

    static getHideComplete() {
        return HelperStudiesStore.getHideComplete();
    }

    static getStudyImages() {
        return HelperStudiesStore.getStudyImages();
    }

    static getImagesToLoad() {
        return HelperStudiesStore.getImagesToLoad();
    }

    static getCurrentPage() {
        return HelperStudiesStore.getCurrentPage();
    }

    static getCurrentSorting() {
        return HelperStudiesStore.getCurrentSorting();
    }

    static getCurrentSearch() {
        return HelperStudiesStore.getCurrentSearch();
    }

    static getFilesSelectedToLoad(study_id, editor, user) {
        if (!editor) {
            editor = user.user_email;
        }
        const files = HelperStudiesStore.getImagesToLoad();
        if (files) {
            return new Promise((resolve, reject) => {
                resolve(files)
            });
        } else {
            return HelperStudiesBackend.getFilesSelectedToLoad(study_id, editor, user.user_token).then((result) => {
                return result.length === 0 ? false : result[0].images;
            });
        }
    }

    static updateFilesSelectedToLoad(study_id, user, images) {
        return HelperStudiesBackend.updateFilesSelectedToLoad(study_id, user, images);
    }

    static initStudy() {
        let site = window.location.pathname;
        if (site.indexOf("/") !== -1) {
            site = site.replace("/", "");
        }
        return this.getStudyInfo().then(study_info => {
            study_info.state = HelperStudiesState.init(site, study_info);
            return study_info;    
        });
    }

    static loadIcons(study_info, user) {
        const images = HelperStudiesStore.getStudyImages();
        if (images && images.all_images.length > 0) {
            return new Promise((resolve, reject) => {
                resolve(images)
            });
        } else {
            return HelperStudiesBackend.loadIcons(study_info.study_id, study_info.patient_id, user.user_token).then((result) => {
                HelperStudiesStore.setStudyImages(result);
                return result;
            });
        }
    }

    static loadStudy(study_info, user) {
        const images = HelperStudiesStore.getStudyImages();
        const images2load = HelperStudies.getImagesToLoad();
        let email = user.user_email;
        if (study_info.editor) {
            email = study_info.editor;
        }
        if (images && images.all_images.length > 0) {
            if (images.analysis_images) {
                return new Promise((resolve, reject) => {
                    resolve(images)
                });
            } else {
                return HelperStudiesBackend.loadImageComments(images.all_images, study_info.study_id, user.user_token, email, user.user_type).then((images_w_comments) => {
                    return HelperStudiesBackend.loadAnalysis(images_w_comments, study_info.study_id, email, user.user_token, user.user_type).then((result) => {
                        HelperStudiesStore.setStudyImages(result);
                        saved_images = removeAttributesFromAnalysisImages(result.analysis_images);
                        saved_missing_images = [...new Set(result.missing_images)];
                        return result;
                    });
                });
            }
        } else {
            return HelperStudiesBackend.loadStudy(study_info.study_id, study_info.patient_id, user.user_token, images2load, email, user.user_type).then((result) => {
                HelperStudiesStore.setStudyImages(result);
                saved_images = removeAttributesFromAnalysisImages(result.analysis_images);
                saved_missing_images = [...new Set(result.missing_images)];
                return result;
            });
        }
    }

    static saveStudy(force) {
        if (["Ground-truth generator","Editor"].includes(HelperUser.getUserType())) {
            const study_id = this.getStudyId();
            let missing_images = this.getStudyImages().missing_images;
            missing_images = missing_images? missing_images : [];
            let analysis_images = this.getStudyImages().analysis_images;
            // remove dicom,urls,etc. from analysis images
            analysis_images = removeAttributesFromAnalysisImages(analysis_images);
            const user = HelperUser.getUser();
            // compare previous study images with current
            // const areEqual = arraysAreEqual(analysis_images,saved_images);
            const areEqual = isEqual(analysis_images, saved_images) && isEqual(missing_images, saved_missing_images);
            if (!force && (areEqual || saving || analysis_images.length === 0)) { // if == or no analysis images or not loaded --> don't save
                return new Promise((resolve, reject) => {
                    saving = false;
                    console.log("Nothing to save")
                    resolve(false);
                });
            } else { // if != save study
                saving = true;
                console.log("Saving...")
                HelperStudiesBackend.saveStudy(study_id, analysis_images, missing_images, user.user_email, user.user_token, false, []).then((result) => {
                    saved_images = analysis_images;
                    saved_missing_images = [...new Set(missing_images)];
                    saving = false;
                });
            }
            if (HelperUser.getUserType() === "Editor") {
                HelperStudies.updateFilesSelectedToLoad(study_id, user, HelperStudiesStore.getImagesToLoad());
            }
        } else {
            return new Promise((resolve, reject) => {
                saving = false;
                console.log("User role not allowed to save changes")
                resolve(false);
            });
        }
    }

    static downloadJSONanalysis() {
        return this.saveStudy().then(() => {
            return this.getStudyInfo().then((study_info) => {
                const user = HelperUser.getUser();
                return HelperStudiesBackend.getStudyAnalysis(study_info.study_id, study_info.editor, user.user_token).then((analysis) => {
                    let dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(analysis, null, 2));
                    let downloadAnchorNode = document.createElement('a');
                    let filename = study_info.study_id + "_" + study_info.editor + "IAP_analysis.json";
                    downloadAnchorNode.setAttribute("href", dataStr);
                    downloadAnchorNode.setAttribute("download", filename);
                    document.body.appendChild(downloadAnchorNode); // required for firefox
                    downloadAnchorNode.click();
                    downloadAnchorNode.remove();
                });
            });
        });
    }
    
    static loadStudyforReview(study_info, user) {
        const images = HelperStudiesStore.getStudyImages();
        if (images && images.analysis_images.length > 0) {
            return new Promise((resolve, reject) => {
                resolve(images)
            });
        } else {
            return HelperStudiesBackend.getReview(study_info.study_id, study_info.editor, user.user_email, user.user_token, user.user_role).then((review) => {
                if (!review && study_info.validated) {
                    return HelperStudiesBackend.getStudyStatus(study_info.study_id, user.user_token).then((status_list) => {
                        let status = findWhere(status_list, { editor: study_info.editor });
                        return HelperStudiesBackend.getReview(study_info.study_id, study_info.editor, status.validated_by, user.user_token, user.user_role).then((review) => {
                            HelperStudies.setStudyState(review.study_state);
                            return HelperStudiesBackend.loadImageComments(review.review, study_info.study_id, user.user_token, status.validated_by, user.user_type).then((images_w_comments) => {
                                return HelperStudiesBackend.loadImages(study_info.study_id, study_info.patient_id, user.user_token, images_w_comments, review.missing_images);
                            });
                        });
                    });
                } else {
                    return this.loadStudy(study_info, user).then(images => {
                        HelperStudiesStore.setOriginalAnalysisImages(Array.from(images.analysis_images));
                        if (review) {
                            HelperStudies.setStudyState(review.study_state);
                            return HelperStudiesBackend.loadImageComments(review.review, study_info.study_id, user.user_token, user.user_email, user.user_type).then((images_w_comments) => {
                                return HelperStudiesBackend.loadImages(study_info.study_id, study_info.patient_id, user.user_token, images_w_comments, review.missing_images);
                            });
                        } else
                            if (study_info.editor.includes("AI")) {
                                HelperSegmentationBMode.correctAIBModeSegmentation(images);
                            }
                            return images;
                    });
                }
            });
        }
    }

    static sortImagesforReview(images) {
        let review_images = [];
        images.analysis_images.map(im => review_images.push(im));
        review_images = review_images.sort((a,b) => Number(a.validated===true)-Number(b.validated===true));
        review_images = review_images.sort((a,b) => b.modality.localeCompare(a.modality));
        if (images.missing_images?.length > 0) {
            for (let miss of images.missing_images) {
                review_images.push({
                    "type": miss,
                    "missing_image": true
                });
            }    
        }
        return review_images;
    }

    static saveReview(force) {
        const reviewer = HelperUser.getUser();
        return this.getStudyInfo().then(study_info => {
            return HelperStudiesBackend.getReview(study_info.study_id, study_info.editor, reviewer.user_email, reviewer.user_token, reviewer.user_role).then((review) => {
                let orig_analysis_images = this.getStudyImages().analysis_images;
                let orig_missing_images = this.getStudyImages().missing_images;
                // remove dicom,urls,etc. from analysis images
                let analysis_images = removeAttributesFromAnalysisImages(orig_analysis_images);
                let missing_images = orig_missing_images? orig_missing_images : [];
                const areEqual = isEqual(analysis_images, saved_images) && isEqual(missing_images, saved_missing_images);
                if (!force && (areEqual || saving || analysis_images.length === 0)) { // if == or no analysis images or not loaded --> don't save
                    return new Promise((resolve, reject) => {
                        saving = false;
                        console.log("Nothing to save")
                        resolve(false);
                    });
                } else { // if != save study
                    if (!review && this.getCurrentProjectObj().qc) {
                        // First time saving the review, create QCs (if needed) with the reviewer name
                        if (this.getCurrentProjectObj().qc.type.includes("quick")) {
                            let accepted_images = orig_analysis_images.filter(img => img.accepted).map(img => { return { filename: img.filename, accepted: img.accepted, type: img.type } });
                            for (let img of accepted_images) {
                                this.saveQuickQC(img, true);
                            }    
                        } else {
                            let scored_images = orig_analysis_images.filter(img => img.score).map(img => { return { filename: img.filename, score: img.score, type: img.type } });
                            for (let img of scored_images) {
                                this.saveQCform(img, true);
                            }    
                        }
                    }    
                    HelperStudiesBackend.saveReview(study_info.study_id, analysis_images, missing_images, study_info.editor, reviewer.user_token, reviewer.user_email).then((result) => {
                        saved_images = analysis_images;
                        saved_missing_images = [...new Set(missing_images)];
                        saving = false;
                    });
                }
            });
        });
    }

    static validateStudy(control) {
        const study_id = this.getStudyId();
        let missing_images = this.getStudyImages().missing_images;
        missing_images = missing_images? missing_images : [];
        let analysis_images = this.getStudyImages().analysis_images;
        // remove dicom,urls,etc. from analysis images
        analysis_images = removeAttributesFromAnalysisImages(analysis_images);
        const reviewer = HelperUser.getUser();
        return this.getStudyInfo().then(study_info => {
            const editor = study_info.editor;
            // console.log(study_info)
            HelperStudiesBackend.validateStudy(study_id, analysis_images, missing_images, editor, reviewer.user_token, reviewer.user_email, control).then((result) => {
                saved_images = analysis_images;
                saved_missing_images = [...new Set(missing_images)];
                saving = false;
            });
        });
    }

    static unvalidateStudy() {
        const reviewer = HelperUser.getUser();
        return this.getStudyInfo().then(study_info => {
            return HelperStudiesBackend.unvalidateStudy(study_info.study_id, study_info.editor, reviewer.user_token, reviewer.user_email);
        });
    }

    static validatedToCheckpoint(study_id, reviewer, editor) {
        const user = HelperUser.getUser();
        return HelperStudiesBackend.validatedToCheckpoint(study_id, reviewer, editor, user.user_token);
    }

    static updateReviewState(study_id, reviewer, editor, new_state) {
        const user = HelperUser.getUser();
        return HelperStudiesBackend.updateReviewState(study_id, reviewer, editor, new_state, user.user_token);
    }

    // ONLY FOR THE PRISMA TEAM REVIEW FUNCTIONALITY
    static deleteTeamReviewFromMinio(study_id) {
        const user = HelperUser.getUser();
        let idx = study_id.lastIndexOf('_');
        let params = { patient: study_id.substr(0, idx) , date: study_id.substr(idx+1) };
        return HelperStudiesBackend.deleteTeamReviewFromMinio(params, user.user_token);
    }

    static setStudyOpen(study_id, open) {
        const user = HelperUser.getUser();
        return HelperStudiesBackend.setStudyOpen(study_id, open, user.user_email, user.user_token).then((result) => {
            return result;
        });
    }

    // static getStudyOpen(study_id) {
    //     const user = HelperUser.getUser();
    //     return HelperStudiesBackend.getStudyOpen(study_id, user.user_token).then((result) => {
    //         return result;
    //     });
    // }

    static checkLastUpdateBeforeMinutes(study_id, editor, minutes) {
        const user = HelperUser.getUser();
        return HelperStudiesBackend.getAnalysisLastUpdate(study_id, editor, user.user_token).then(last_update => {
            const offset = new Date().getTimezoneOffset();
            const now = Date.now() + offset*60*1000;
            if (now-Date.parse(last_update) > minutes*60*1000) {
                return false;
            } else {
                return true;
            }
        });
    }

    static checkStudyOpen(is_open, last_update) {
        if (is_open) {
            const now = new Date();
            if (typeof is_open === "string") {
                const open_date = new Date(is_open);
                open_date.setDate(open_date.getDate() + 1);
                if (now < open_date) {
                    return true;
                }
            }
            const expire_date = new Date(last_update);
            expire_date.setDate(expire_date.getDate() + 1);
            return now < expire_date;
        }
        return false;
    }

    // PROJECT IMAGE REQUIREMENTS
    static studyIsComplete(images, missing_images) {
        let requirements = this.getCurrentProjectObj().image_requirements;
        if ((images && images.length > 0) || (missing_images && missing_images.length > 0)) {
            if (requirements) {
                if (this.listMeetsRequirements(requirements, "type", images.map(i => i.type), missing_images, true)) {
                    return images.every(img => HelperStudies.imageMeetsRequirements(requirements, img));
                } else {
                    return false;
                }
            } else {
                return true;
            }
        } else {
            return false;
        }
    }
    static studyIsCompleteFromStatus(status, scores) {
        let requirements = this.getCurrentProjectObj().image_requirements;
        let missing = status.missing_images? status.missing_images : [];
        if (status.images_selected.length > 0) {
            let filter_bio = status.images_segmented.filter(type => HelperConfig.getBiometryTypes().includes(type));
            let filter_2d = status.images_segmented.filter(type => type.includes(" 2D"));
            let filter_dop = status.images_segmented.filter(type => !HelperConfig.getBiometryTypes().includes(type) && !type.includes(" 2D") && !type.includes(" M-Mode"));
            let conditions = [
                this.listMeetsRequirements(requirements, "qc", scores? scores.map(s=>s.image_label) : [], missing, true),
                this.listMeetsRequirements(requirements, "type", status.images_selected, missing, false),
                this.listMeetsRequirements(requirements, "cycletiming", status.images_calibrated, missing, true),
                this.listMeetsRequirements(requirements, "segmentation_bio", filter_bio, missing, true),
                this.listMeetsRequirements(requirements, "segmentation_2d", filter_2d, missing, true),
                this.listMeetsRequirements(requirements, "segmentation_dop", filter_dop, missing, true)
            ];
            return conditions.every(c => c);
        } else {
            return false;
        }
    }
    static listMeetsRequirements(reqs, type, list, exceptions, allow_empty) {
        if ((!list || list.length === 0) && !allow_empty) {
            return false;
        } else if (reqs) {
            list = list? list : [];
            exceptions = exceptions? exceptions : [];
            let imtypes_req = reqs.filter(r => r[type]).map(r => r.type);
            let missing = imtypes_req.filter(t => !list.includes(t) && !exceptions.includes(t));
            return missing.length > 0? false : true;
        } else {
            return true;
        }
    }
    static imageMeetsRequirements(reqs, image) {
        if (reqs) {
            let imreq = findWhere(reqs, { type: image.type });
            if (imreq) {
                if (imreq.qc && !image.score && !image.accepted) { return false; }
                if (imreq.cycletiming && !image.is_annotated) { return false; }
                if (imreq.segmentation && !image.is_segmented) { return false; }
                return true;
            } else {
                return true;
            }
        } else {
            return true;
        }
    }
    static attributeIsRequired(reqs, type, att) {
        if (reqs) {
            let imreq = findWhere(reqs, { type: type });
            return imreq? imreq[att] : false;
        } else {
            return false;
        }
    }
    static checkStudyState() {
        if (!(HelperUser.getUserType() === "Reviewer" && HelperStudies.getValidated()) && this.getStudyImages()) {
            let images = this.getStudyImages();
            let reference_images = images.analysis_images.filter(img => img["reference"]);
            let requirements = this.getCurrentProjectObj().image_requirements;
            let study_state = "Incomplete";
            if (this.studyIsComplete(reference_images, images.missing_images)) {
                if (HelperUser.getUserType() === "Reviewer") {
                    let original_analysis = HelperStudiesStore.getOriginalAnalysisImages();
                    let images_required = reference_images.filter(img => requirements.map(req => req.type).includes(img.type));
                    let cycles_segmented = images_required.map(img => img.segmentation[0]?.lines[0].points.length > 0? img.cardiac_events.length : 0);
                    let cycles_required = HelperStudies.getCurrentProjectRequiredCycles();
                    let editor_qcs = images_required.map(img => {
                        let orig_img = findWhere(original_analysis, { filename: img.filename });
                        return orig_img?.score? orig_img.score.General[0].value : false;
                    });
                    let reviewer_qcs = images_required.map(img => img.score.General[0].value);
                    if (images.missing_images.length > 0 || (isEqual(reviewer_qcs,editor_qcs) && cycles_segmented.includes(0))) {
                        study_state = "Not valid";
                    } else if (reviewer_qcs.every(qc => qc==="No") && editor_qcs.every(qc => qc==="No") && (!cycles_required || cycles_segmented.every(c => c===cycles_required))) {
                        study_state = "Valid";
                    } else {
                        study_state = "Consensus pending"
                    }
                } else if (this.studyIsComplete(reference_images, [])) {
                        let good_imgs = reference_images.filter(img => HelperStudies.isPositiveQC(img));
                        let valid_study = this.listMeetsRequirements(requirements, "qc", good_imgs.map(i => i.type), [], true);
                        study_state = valid_study? "Valid" : "Not valid";
                } else {
                    study_state = "Not valid";
                }
            } else if (HelperUser.getUserType() === "Reviewer") {
                study_state = "Not valid";            
            }
            this.setStudyState(study_state);
        }
    }

    // COMMENTS
    static getComments(image_filename) {
        const user = HelperUser.getUser();
        return HelperStudiesBackend.getComments(this.getStudyId(), image_filename, user.user_token).then((result) => {
            return result;
        });
    }
    static saveComment(comment, update, is_saving_review) {
        const user = HelperUser.getUser();
        return HelperStudiesBackend.saveComment(comment, update, user.user_token).then((result) => {
            HelperStudies.checkStudyState();
            if (HelperUser.getUserType() === "Reviewer" && !is_saving_review) {
                this.saveReview(true);
            }
            return result;
        });
    }
    static isPositiveQC(image) {
        return image.accepted === "yes" || (image.score && image.score.General[0].value === "No");
    }
    static saveQCform(image, is_saving_review) {
        const user = HelperUser.getUser();
        HelperStudiesBackend.getComments(this.getStudyId(), image.filename, user.user_token).then((comments) => {
            let done = findWhere(comments, { user: user.user_email, type: "score" });
            if (!done || done.image_label !== image.type || done.content !== image.accepted) {
                const comment_obj = {
                    date: String(new Date()),
                    user: user.user_email,
                    initials: user.user_name.charAt(0) + user.user_family_names.charAt(0),
                    content: image.score,
                    type: "score",
                    study_id: this.getStudyId(),
                    image_filename: image.filename,
                    image_label: image.type
                };
                HelperStudies.saveComment(comment_obj, done? true : false, is_saving_review);   
            }
        });
    }
    static saveQuickQC(image, is_saving_review) {
        const user = HelperUser.getUser();
        HelperStudiesBackend.getComments(this.getStudyId(), image.filename, user.user_token).then((comments) => {
            let done = findWhere(comments, { user: user.user_email, type: "score-accept" });
            if (!done || done.image_label !== image.type || done.content !== image.accepted) {
                const comment_obj = {
                    date: String(new Date()),
                    user: user.user_email,
                    initials: user.user_name.charAt(0) + user.user_family_names.charAt(0),
                    content: image.accepted,
                    type: "score-accept",
                    study_id: this.getStudyId(),
                    image_filename: image.filename,
                    image_label: image.type
                };
                HelperStudies.saveComment(comment_obj, done? true : false, is_saving_review);   
            }
        });
    }
    static deleteImageQCs(image_filename) {
        const user = HelperUser.getUser();
        let comment_types = ["score","score-accept"];
        return HelperStudiesBackend.removeImageComments(this.getStudyId(), image_filename, user.user_email, comment_types, user.user_token)
    }
    static removeComment(comment) {
        const user = HelperUser.getUser();
        return HelperStudiesBackend.removeComment(comment.content, comment.user, comment.date, user.user_token).then((result) => {
            return result;
        });
    }
    //

    static resetStudyInfo() {
        this.setStudyId(false);
        this.setStudyDate(false);
        this.setPatientId(false);
        this.setPatientIdShow(false);
        this.setDataType(false);
        this.setStudyImages(false);
        this.setImagesToLoad(false);
        this.setStudyState(false);
        this.setEditor(false);
        this.setValidated(undefined);
        saved_images = [];
        saved_missing_images = [];
    }

    static getStudyInfo() {
        let study_info = {};
        study_info.project = this.getCurrentProject() ? this.getCurrentProject() : this.getUrlParam('project');
        study_info.study_id = this.getStudyId() ? this.getStudyId() : this.getUrlParam('study_id');
        study_info.study_date = this.getStudyDate() ? this.getStudyDate() : this.getUrlParam('study_date');
        study_info.patient_id = this.getPatientId() ? this.getPatientId() : this.getUrlParam('patient_id');
        study_info.patient_id_show = this.getPatientIdShow() ? this.getPatientIdShow() : this.getUrlParam('patient_id_show');
        study_info.data_type = this.getDataType() ? this.getDataType() : this.getUrlParam('data_type');
        study_info.editor = this.getEditor() ? this.getEditor() : this.getUrlParam('editor');
        study_info.validated = this.getValidated() !== undefined ? this.getValidated() : [true, "true"].includes(this.getUrlParam('validated'));
        if (study_info.study_id) { this.setStudyId(study_info.study_id); }
        if (study_info.study_date) { this.setStudyDate(study_info.study_date); }
        if (study_info.patient_id) { this.setPatientId(study_info.patient_id); }
        if (study_info.patient_id_show) { this.setPatientIdShow(study_info.patient_id_show); }
        if (study_info.data_type) { this.setDataType(study_info.data_type); }
        if (study_info.editor) { this.setEditor(study_info.editor); }
        if (study_info.validated !== undefined) { this.setValidated(study_info.validated); }
        if (study_info.project) { 
            return this.setCurrentProject(study_info.project).then(()=>{
                return study_info;
            });
        } else {
            return new Promise((resolve, reject) => {
                resolve(study_info)
            });
        }
    }

    static getUrlParam(param) {
        let url = window.location.search.substring(1);
        let urlParams = new URLSearchParams(codeString(url, "decode"));
        let value = urlParams.get(param) === "undefined" || urlParams.get(param) === null ? undefined : urlParams.get(param);
        return value;
    }

}


// AUX functions
function parseAnalysisList(studies, analysis_list, scores_list, editor, user_type, roles) {
    let studies_analysis = [];
    for (const study of studies) {
        // console.log(analysis_list)
        let all_analysis = where(analysis_list, { id: study["id"] });
        let all_scores = findWhere(scores_list, { id: study["id"] });

        if (["Viewer","Editor","Ground-truth generator"].includes(user_type)) {
            let test_users = HelperConfig.getTestUsers();
            let analysis = findWhere(all_analysis, { id: study["id"], editor: editor, medical_data_type: "echo" });
            let scores = all_scores? all_scores.scores.filter(s => s.user === editor) : false;

            // HARDCODE FOR FEDOC SEGMENTATION DEVYANI/LEA (DO NOT SHOW LEA'S ANALYSIS IN FEDOC)
            if (HelperStudies.getCurrentProject() === "fedoc") {
                test_users.push("lea.tst95@gmail.com");
            }
            // ---------------------------------------

            if (user_type !== "Ground-truth generator" && all_analysis.length > 0) {
                let all_analysis_filter = all_analysis.filter(a => a.total_images > 0 && (!test_users.includes(a.editor) || HelperStudies.getCurrentProject() === "test"));
                let dates = all_analysis_filter.map(a => Date.parse(a.updated_at));
                analysis = all_analysis_filter[dates.indexOf(Math.max(...dates))];
                analysis = analysis? analysis : all_analysis_filter[0];
                scores = all_scores? all_scores.scores : false;
            }
            const study_analysis = createStudyDictForTable(study, analysis, scores, user_type, editor);
            // if (roles.user_role.includes("Reviewer")) {
                const reviews = [];
                for (const other_analysis of all_analysis) {
                    if (other_analysis.validated && !test_users.includes(other_analysis.validated_by)) {
                        reviews.push(other_analysis.validated_by);
                    }
                }
                if (reviews.length > 0) {
                    const text = reviews.length === 1 ? "1 reviewer" : reviews.length + " reviewers";
                    study_analysis.validated_by = [text];
                }
            // }
            studies_analysis.push(study_analysis);
        } else if (user_type === "Admin" || user_type === "Reviewer") {
            all_analysis = all_analysis.filter(analysis => {
                if (analysis.editor) {
                    let editor = Array.isArray(analysis.editor)? analysis.editor[0] : analysis.editor;
                    return roles.reviews_to.includes(editor);
                }
                return false;
            });
            for (const analysis of all_analysis) {
                // HARDCODE FOR PRISMA (DON'T SHOW STUDIES WITHOUT STUDY TYPE TO REVIEWERS)
                if (!(HelperStudies.getCurrentProject() === "fedoc-arc" && !study["study_type"])) {
                // ---------------------------------------
                    let scores = all_scores? all_scores.scores.filter(s => s.user === analysis.editor) : false;
                    if (HelperStudies.studyIsCompleteFromStatus(analysis, scores)) {
                        const study_analysis = createStudyDictForTable(study, analysis, scores, user_type, editor);
                        studies_analysis.push(study_analysis);
                    }
                }
            }
        }
    }
    return studies_analysis;
}
function createStudyDictForTable(study, analysis, scores, user_type, user) {
    let project_info = HelperStudiesStore.getCurrentProjectObj();
    let missing = analysis && analysis.missing_images? analysis.missing_images : [];
    let study_analysis = {};
    // study_analysis["id"] = study["patient_id"];
    study_analysis["date"] = study["date"];
    study_analysis["patient"] = study["patient_id"];
    study_analysis["patient_show"] = study["patient_id_show"];
    study_analysis["project"] = project_info["_id"];
    study_analysis["study_id"] = study["id"];
    study_analysis["is_open"] = study["is_open"];
    study_analysis["data_type"] = study["type_of_data"];
    study_analysis["study_type"] = study["study_type"];
    study_analysis["analysis"] = false;
    study_analysis["editor"] = false;
    study_analysis["updated_at"] = false;
    study_analysis["validated_by"] = [];
    study_analysis["status"] = {
        qc: false,
        selected: false,
        annotated: false,
        segmented_dop: false,
        segmented_2d: false,
        segmented_bio: false,
        measured: false,
        validated: false
    }
    let requirements = project_info.image_requirements;
    if (project_info.qc?.show_status) {
        study_analysis.status.qc = scores? HelperStudies.listMeetsRequirements(requirements, "qc", scores.map(s=>s.image_label), missing, false) : false;
    } else {
        delete study_analysis.status.qc;
    }
    if (study.type_of_data !== "fetal") {
        delete study_analysis.status.segmented_bio;
    }

    if (analysis) {
        study_analysis.validated_by = analysis.validated_by? analysis.validated_by : [];
        study_analysis.status.validated = analysis.validated? analysis.validated : false;

        if (user === analysis.editor || user_type !== "Ground-truth generator") {
            study_analysis.analysis = analysis;
            study_analysis.editor = analysis.editor;

            study_analysis.status.selected = HelperStudies.listMeetsRequirements(requirements, "type", analysis.images_selected, missing, false);
            study_analysis.status.annotated = HelperStudies.listMeetsRequirements(requirements, "cycletiming", analysis.images_calibrated, missing, false);

            if (analysis.images_segmented.length > 0) {
                let filter = [];
                if (study.type_of_data === "fetal") {
                    filter = analysis.images_segmented.filter(type => HelperConfig.getBiometryTypes().includes(type));
                    study_analysis.status.segmented_bio = HelperStudies.listMeetsRequirements(requirements, "segmentation_bio", filter, missing, false);
                }
                filter = analysis.images_segmented.filter(type => type.includes(" 2D"));
                study_analysis.status.segmented_2d = HelperStudies.listMeetsRequirements(requirements, "segmentation_2d", filter, missing, false);
                filter = analysis.images_segmented.filter(type => !HelperConfig.getBiometryTypes().includes(type) && !type.includes(" 2D") && !type.includes(" M-Mode"));
                study_analysis.status.segmented_dop = HelperStudies.listMeetsRequirements(requirements, "segmentation_dop", filter, missing, false);
            }

            if (analysis.updated_at) {
                let date = analysis.updated_at.split(" ");
                date = date.length < 2? date[0].split("T") : date;
                study_analysis.updated_at = date[0];
            }
        }
    }
    return study_analysis
}
function removeAttributesFromAnalysisImages(images) {
    const new_images = images.map(img => {
        const { dicom, urls, comments, score, accepted, ...new_img } = img;
        return new_img;
    });
    return new_images;
}
function classifyScores(scores) {
    let classified = [];
    let ids = [...new Set(scores.map(s => s.study_id))];
    ids.map(id => {
        let study_scores = scores.filter(s => s.study_id === id);
        classified.push({
            id,
            scores: study_scores
        });
    })
    return classified;
}
