import React from 'react';

import { findWhere } from 'underscore';
import { isValueInRange, meanValue } from '../../../modules/math_module';

// Components
import { DicomViewerComponent } from './../data_viewer_component/DICOM/dicom_viewer_component';

// Helpers
import { HelperConfig } from './../../../helpers/helper_config/helper_config';
import { HelperMeasurements } from './../../../helpers/helper_measurements/helper_measurements';
import { HelperStudies } from './../../../helpers/helper_studies/helper_studies';

export const MeasurementsSummary = ({
        measurements,
        images
    }) => {

    const projectSummary = HelperConfig.getProjectMeasurements(HelperStudies.getCurrentProject());
    const projectModalities = HelperConfig.getSummaryModalities(HelperStudies.getCurrentProject());

    const getValueColor = (value, type, name) => {
        const check = HelperConfig.getMeasurementsPreference("check_normal_range");
        if (check) {
            const range = HelperConfig.getMeasurementNormalRange(type, name);
            const vir = isValueInRange(value, range);
            if (vir) {
                return HelperConfig.getMeasurementsPreference("in_range_color");
            } else if (vir === undefined) {
                return HelperConfig.getMeasurementsPreference("undefined_color");
            }
            return HelperConfig.getMeasurementsPreference("out_range_color");
        } else {
            return HelperConfig.getMeasurementsPreference("in_range_color");
        }
    }

    // --- RENDER METHODS

    const renderMeasure = (measures, margin, colors) => {
        const abs = HelperConfig.getMeasurementsPreference("absolute_values");
        const values_to_show = [];
        const colors_to_show = [];
        measures.map(m => {
            if (m.value.length > 0) {
                let values = abs.includes(m.units)? m.value.map(v => Math.abs(v)) : m.value;
                values_to_show.push(meanValue(values));
                colors_to_show.push(findWhere(colors, { image_id: m.image_id }).color);    
            }
        });
        // const mean_color = getValueColor(mean, imtype, name);
        const measure_obj = measures[0];
        const abv = HelperConfig.getMeasurementAbbreviation(measure_obj.from, measure_obj.name);
        return (
            <div key={measure_obj.name} className="grid-block horizontal " style={{ fontSize: "14px", margin, whiteSpace: "nowrap", overflow: "hidden" }}>
                <div style={{ fontWeight: "bold", paddingRight: "2px", marginRight: "2px" }}>{abv}: </div>
                {values_to_show.map((v,i) => {
                    if (i === values_to_show.length - 1){
                        return <div style={{ color: colors_to_show[i], paddingRight: "2px", fontStyle: "italic", fontWeight: "bold" }}>{v}</div>
                    } else {
                        return <>
                            <div style={{ color: colors_to_show[i], paddingRight: "2px", fontStyle: "italic", fontWeight: "bold" }}>{v}</div>
                            <div style={{ paddingRight: "2px", fontStyle: "italic", fontWeight: "bold" }}>,</div>
                        </>
                    }
                })}
                <div style={{ paddingRight: "2px", fontStyle: "italic" }}>{measure_obj.units}</div>
            </div>
        );
    }

    const renderMeasurements = (measurements, list, colors) => {
        let margin = "0px 15px 10px";
        let measures = [];
        measurements.modalities.map(mod => {
            if (projectModalities.includes(mod.name)) {
                measures.push(...mod.measurements);
            }
        });
        return list.map((item,i) => {
            let measures_type = measures.filter(meas => meas.name === item && meas.value.length > 0);
            if (measures_type.length > 0) {
                return renderMeasure(measures_type, margin, colors);
            }
        });
    }

    const renderImages = (colors_images) => {
        return colors_images.map(img => {
            const file = findWhere(images, { id: img.image_id });
            const style = { outline: "solid 2px "+img.color };
            return (
                <div className="image-container" style={style}>
                    <DicomViewerComponent
                        site={"measurements"}
                        image_to_show={file}
                        play_multiframe={true}
                        play_by_default={false}
                        show_player_controls={false}
                    />
                </div>
            );
        });
    }

    const renderMeasurementsAndImages = (measurements, list) => {
        const colors_images = HelperMeasurements.getListOfImagesWithColors(measurements);
        const filtered_images = colors_images.filter(img => measurements.modalities.some(mod => 
            mod.measurements.some(meas => list.includes(meas.name) && meas.value.length > 0 && meas.image_id === img.image_id)
        ));
        if (filtered_images.length > 0) {
            return (
                <>
                    <div>{renderMeasurements(measurements, list, filtered_images)}</div>
                    <div className="images-area">{renderImages(filtered_images)}</div>
                </>
            );
        }
    }

    // const renderMeasurementsByType = (measures, color) => {
    //     const direct = measures.filter(m => m.type === "direct");
    //     const calculated = measures.filter(m => m.type === "calculated");
    //     let grid = "repeat(auto-fill, minmax(280px,450px))";
    //     let borderBottom = "";
    //     if (direct.length > 0){
    //         borderBottom = "1px solid " + color;
    //     }
    //     if (showTypes.includes("Direct") && showTypes.includes("Calculated")) {
    //         return (
    //             <React.Fragment>
    //                 <div style={{ borderBottom: borderBottom, display: "grid", gridTemplateColumns: grid }}>{renderMeasurements(direct, "direct")}</div>
    //                 <div style={{ display: "grid", gridTemplateColumns: grid }}>{renderMeasurements(calculated, "calculated")}</div>
    //             </React.Fragment>
    //         )
    //     } else if (showTypes.includes("Direct")  && direct.length > 0) {
    //         return (
    //             <div style={{ display: "grid", gridTemplateColumns: grid }}>{renderMeasurements(direct, "direct")}</div>
    //         )
    //     } else if (showTypes.includes("Calculated") && calculated.length > 0) {
    //         return (
    //             <div style={{ display: "grid", gridTemplateColumns: grid }}>{renderMeasurements(calculated, "calculated")}</div>
    //         )
    //     }
    // }
    
    // const renderMeasurementsByModality = (anatomy) => {
    //     let padding = "10px";
    //     if (showTypes.includes("Calculated") && !showTypes.includes("Direct")) { padding = "3px 10px 10px" }
    //     if (!showTypes.includes("Calculated") && showTypes.includes("Direct")) { padding = "10px 10px 3px" }
    //     const measure = findWhere(measurements, { anatomy: anatomy.name_short });
    //     if (measure) {
    //         return measure.modalities.map((modality) => {
    //             return (
    //                 <div key={modality.name} style={{ display: "grid", gridTemplateColumns: "35px minmax(200px, 1fr)" }}>
    //                     <div style={{ color: modality.color, fontSize: "12px", margin: "10px" }}>
    //                         <div style={{ transform: "rotate(-90deg)", position: "relative", top: "50%" }}>
    //                             {modality.name}
    //                         </div>
    //                     </div>
    //                     <div style={{ borderTop: "1px solid " + modality.color, borderBottom: "1px solid " + modality.color, margin: "0px 20px 10px 0px", padding }}>
    //                         {/* <PlotComponent
    //                             show_right={this.state.show_right}
    //                             show_left={this.state.show_left}
    //                             measurements={modality.measurements}
    //                             plot_type={"flow"}
    //                             analysis={this.props.analysis}
    //                             modality={modality}
    //                         /> */}
    //                         {renderMeasurementsByType(modality.measurements, modality.color)}
    //                     </div>
    //                 </div>
    //             )
    //         })
    //     }
    // }

    const renderAnatomyBoxes = () => {
        const anatomies = HelperConfig.getAnatomies();
        if (projectSummary) {
            return Object.keys(projectSummary).map((anatomy) => {
                const measured = findWhere(measurements, { anatomy: anatomy });
                if (measured) {
                    const anatomy_object = findWhere(anatomies, { name_short: anatomy });
                    return (
                        <div className={"anatomy-box summary"} key={anatomy}>
                            <div style={{ textAlign: "center", margin: "10px 10px 15px" }}>
                                {anatomy_object.name_short}
                                <span style={{ marginLeft: "8px", fontStyle: "italic", color: "#ccc", fontSize: "12px" }}>{anatomy_object.name_long}</span>
                            </div>
                            <div style={{ display: "flex" }}>
                                {renderMeasurementsAndImages(measured, projectSummary[anatomy])}
                            </div>
                        </div>
                    );
                }
            });
        }
    }

    return (
        <div className="grid summary">
            {renderAnatomyBoxes()}
        </div>
    );
}
